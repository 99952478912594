import React, { useState, useEffect } from "react";
import SlideFrontEnd from "./slides/SlideFrontEnd";
import SlideWebdesign from "./slides/SlideWebdesign";
import SlideAccueil from "./slides/SlideAccueil";
import SlideCommDigitale from "./slides/SlideCommDigitale";

function Diaporama({ setModalContent, setModalColor, toggleModal }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const SLIDE_HEIGHT = 75;

  const items = [
    <SlideAccueil goToSlide={setCurrentIndex} setModalContent={setModalContent} setModalColor={setModalColor} toggleModal={toggleModal} />,
    <SlideFrontEnd goToSlide={setCurrentIndex} setModalContent={setModalContent} setModalColor={setModalColor} toggleModal={toggleModal} />,
    <SlideWebdesign goToSlide={setCurrentIndex} setModalContent={setModalContent} setModalColor={setModalColor} toggleModal={toggleModal} />,
    <SlideCommDigitale goToSlide={setCurrentIndex} setModalContent={setModalContent} setModalColor={setModalColor} toggleModal={toggleModal} />,
  ];

  const nextItem = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, items.length - 1)); 
  };

  const prevItem = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0)); 
  };

  const handleWheel = (event) => {
    if (isScrolling) return; 

    setIsScrolling(true);

    if (event.deltaY > 0) {
      nextItem(); // Scroll vers le bas
    } else {
      prevItem(); // Scroll vers le haut
    }

    setTimeout(() => {
      setIsScrolling(false);
    }, 1000);
  };

  useEffect(() => {
  const handleScroll = (event) => handleWheel(event);
  window.addEventListener("wheel", handleScroll);
  return () => window.removeEventListener("wheel", handleScroll);
}, [isScrolling]);

  return (
    <><div className="z-40 w-full overflow-hidden relative ">
      <div className="w-full h-full flex flex-col transition-transform duration-500 ease-in-out"
        style={ {
          transform: `translateY(-${currentIndex * SLIDE_HEIGHT}vh)`,
        } }
      >
        { items.map((item, index) => (
          <div
            key={ index }
            className="w-full h-[75vh] flex justify-center items-center"
          >
                <section className="w-full h-[75vh] flex items-start md:items-center justify-center animate-slideIn">
              { item }
              </section>
          </div>
        )) }
      </div>
    </div>
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex flex-row items-center gap-8 z-50">
        <div className="absolute inset-0 bg-gradient-to-t from-white via-white/70 to-transparent pointer-events-none md:bg-none md:pointer-events-auto"></div>

        <div className="relative flex flex-row items-center gap-8 z-10">
          <button
            onClick={ prevItem }
            disabled={ currentIndex === 0 }
            className={ `p-2 rounded-full bg-white shadow-md hover:shadow-lg hover:bg-black hover:shadow-survol-circle hover:text-white transition ${currentIndex === 0 ? "hidden" : "opacity-100"}` }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 transform -rotate-90"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7" />
            </svg>
          </button>

          <button
            onClick={ nextItem }
            disabled={ currentIndex === items.length - 1 }
            className={ `p-2 rounded-full bg-white shadow-md hover:shadow-lg hover:bg-black hover:shadow-survol-circle hover:text-white transition ${currentIndex === items.length - 1 ? "hidden" : "opacity-100"}` }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 transform rotate-90"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div></>
  );
}

export default Diaporama;
