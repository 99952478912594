import React from "react";
import ContactForm from '../../contact/ContactForm';

function SlideAccueil({ setModalContent, setModalColor, toggleModal  }) {
      const colorModal = () => {
    setModalColor("bg-[#FAFAFA]");
  };
    const contentModal = () => {
    setModalContent(<ContactForm />);
  };
  return (
      <div className="h-[70vh] flex items-center justify-center mx-auto my-2">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="w-11/12 max-w-7xl mx-auto flex items-center justify-center animate-slideInLeft">
          <img
            className="w-1/3 md:w-1/4 lg:w-1/3 h-auto"
            src="./img/fa.png"
            alt="Flavie André"
          />
        </div>

        <div className="flex flex-col justify-center text-center lg:text-left space-y-2 animate-slideInRight">
          <h1 className="text-xl text-center lg:text-2xl font-bold">
            Webdesigner & développeuse web front-end
          </h1>
          <p className="text-lg lg:text-xl text-gray-600 py-2">
          Des outils et des supports pour vous faciliter le web et vous rendre plus visible !
          </p>
            <div>
              <button
              type="button"
              onClick={() => {
                toggleModal();
                colorModal();
                contentModal();
              }}
            className="mx-auto font-neuton py-2 px-6 tracking-wide rounded-full bg-black text-white shadow flex items-center justify-center
 transition duration-500 hover:shadow-survol-btn hover:scale-105"
            >
              En savoir plus
            </button>
           
            {/* <ContactFormModal /> */}
          </div>
        </div>
        </div></div>    
  );
}

export default SlideAccueil;
