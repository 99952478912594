import React from "react";
// import Modal from "../../assets/Modal";
import SlideCommDigitaleModal from "./SlideCommDigitaleModal";

function SlideCommDigitale({ setModalContent, setModalColor, toggleModal }) {

    const colorModal = () => {
    setModalColor("bg-[linear-gradient(45deg,_#4bb7a4_50%,_#7fc6b6_50%)]");
  };
    const contentModal = () => {
    setModalContent(<SlideCommDigitaleModal />);
  };

    return (
      <div className="mx-auto my-2 animate-slideIn">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-4 animate-slideInLeft">
          <div className="px-4 md:px-10 py-2 lg:col-span-3">
             <h3 className="pr-4 text-xl text-left lg:text-2xl font-bold">
    Communication digitale
              </h3>
<div className="flex items-center relative lg:my-4 lg:py-4">
               
                <div className="flex-grow lg:flex-none lg:w-1/5 h-[1px] bg-black -mr-2 "></div>
            <img
              src={`${process.env.PUBLIC_URL}/img/rondVert.svg`}
              className="z-10 w-8 h-8 lg:w-12 lg:h-12 over:scale-105 lg:hidden"
              alt="FA"
            />
          </div>
            <ul className="text-lg lg:text-xl text-gray-600 text-left md:px-6 lg:px-2 list-disc list-inside mb-6 leading-snug">
              <li>Gestion de projets web, rédaction de cahier des charges</li>
              <li>Mise en œuvre de stratégie digitale</li>
              <li>Optimisation du référencement naturel (SEO)</li>
              <li>Conception de campagnes emailings</li>
            </ul>
            <button
              type="button"
              onClick={() => {
                toggleModal();
                colorModal();
                contentModal();
              }}
            className="font-neuton py-2 px-6 tracking-wide rounded-full bg-black text-white shadow flex items-start justify-center
 transition duration-500 hover:shadow-survol-btn hover:scale-105"
            >
              En savoir plus
            </button>
          </div>
                   <div
  className="hidden lg:block relative rounded-lg shadow-lg p-4 flex items-center justify-center lg:col-span-1 "
  style={{
    backgroundImage: 'url(https://picsum.photos/id/668/2400/1600.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}
>
  <img
    src={`${process.env.PUBLIC_URL}/img/rondVert.svg`}
    className="absolute bottom-0 left-0 z-10 w-12 h-12 transform translate-x-1/2 translate-y-1/2 transition-transform hover:scale-105 animate-slideInRight"
    alt="rondVert"
  />
</div>

        </div>
      </div>
  );
}

export default SlideCommDigitale;
