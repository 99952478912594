import React, { useState } from "react";
import Modal from "../assets/Modal";
import Conditions from "./Conditions";
import Mentions from "./Mentions";
import Confidentialite from "./Confidentialite";


function Footer() {
  const [isMentionsModalVisible, setIsMentionsModalVisible] = useState(false);
  const [isConditionsModalVisible, setIsConditionsModalVisible] = useState(false);
    const [isConfidentialiteModalVisible, setIsConfidentialiteModalVisible] = useState(false);


  const toggleMentionsModal = () => {
    setIsMentionsModalVisible((prev) => !prev);
  };

  const toggleConditionsModal = () => {
    setIsConditionsModalVisible((prev) => !prev);
  };
  const toggleConfidentialiteModal = () => {
    setIsConfidentialiteModalVisible((prev) => !prev);
  };

  return (
    <div className="w-full transition-opacity duration-500 border-t border-gray-200">
      <div className="flex flex-col">
        <div className="text-center text-sm text-gray-500">
          <ul className="my-2 flex flex-col sm:flex-col md:flex-row md:space-x-6 text-center items-center md:justify-end justify-center">
              <li>
                <button
                  className="hover:underline hover:underline bg-transparent"
                  onClick={toggleMentionsModal}
                >
                  Mentions légales
                </button>
              </li>
              <li>
                <button
                  className="hover:underline hover:underline bg-transparent"
                  onClick={toggleConfidentialiteModal}
                >
                  Confidentialité
                </button>
              </li>
              <li>
                <button
                  className="hover:underline over:underline bg-transparent"
                  onClick={toggleConditionsModal}
                >
                  Conditions générales
                </button>
              </li>
              <li>
                <span>© 2025 v.1.2</span>
              </li>
            </ul>
        </div>
      </div>

      <Modal
        className="w-full mx-auto"
        isModalVisible={isMentionsModalVisible}
        toggleModal={toggleMentionsModal}
        modalContent={<Mentions />}
        modalColor="bg-[linear-gradient(45deg,_#4bb7a4_50%,_#7fc6b6_50%)]"
      />

      <Modal
        className="w-full mx-auto"
        isModalVisible={isConditionsModalVisible}
        toggleModal={toggleConditionsModal}
        modalContent={<Conditions />}
        modalColor="bg-[linear-gradient(95deg,_#eb6281_50%,_#e73c5b_50%)]"
      />

       <Modal
        className="w-full mx-auto"
        isModalVisible={isConfidentialiteModalVisible}
        toggleModal={toggleConfidentialiteModal}
        modalContent={<Confidentialite />}
        modalColor="bg-[linear-gradient(65deg,_#7d7eb5_50%,_#5d62a2_50%)]"
      />
    </div>
  );
}

export default Footer;
