import React, { useState } from "react";
// import ContactFormModal from "./contact/ContactFormModal";
import Modal from "./assets/Modal";
import ContactForm from "./contact/ContactForm";


const Navbar = () => {

    const [isModalVisible, setIsModalVisible] = useState(false);


  const toggleModal = () => {
    setIsModalVisible((prev) => !prev);
  };
  
    return (
      
<nav
  className={`z-50 md:h-[25vh] flex flex-col md:flex-row gap-2 md:gap-6 sm:gap-2 border-b-2 border-black p-4 pb-4 items-center justify-center bg-[#FAFAFA]`}
        ><a href="/" aria-label="Retour à l'accueil">
        <div className="flex items-center flex-grow md:flex-grow-0 w-20 md:w-28 hover:scale-105">
    <img src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="FA" />
  </div></a>

  <div className="flex flex-col md:flex-grow items-center md:items-start ">
    <h1 className="font-neuton text-xl md:text-2xl font-bold ">Flavie André</h1>
    <h2 className="font-neuton text-center md:text-start md:text-2xl text-black px-4 md:px-0 md:pr-2">
      Webdesign, développement web front-end <span className="hidden lg:block">
  </span> & communication digitale
    </h2>
    
  </div>



  <div
    className="flex sm:flex flex-col flex-grow items-center gap-4"
  >
    <ul className="flex items-center gap-4">
            <li>
               
    <a
      href="https://www.linkedin.com/in/flavie-andr%C3%A9-37500065"
      target="_blank"
      className="text-black transition-colors duration-300 "
      rel="noreferrer">
                <button  className="rounded-full hover:scale-105 hover:shadow-survol-circle">
      <svg viewBox="0 0 24 24" fill="currentColor" className="h-6">
        <path d="M22.23 0H1.77C.79 0 0 .78 0 1.75v20.5C0 23.22.79 24 1.77 24h20.5c.98 0 1.77-.78 1.77-1.75V1.75C24 .78 23.21 0 22.23 0zM7.12 20.45H3.56V9.03h3.56v11.42zM5.34 7.58a2.07 2.07 0 01-2.06-2.06c0-1.14.92-2.06 2.06-2.06s2.06.92 2.06 2.06a2.07 2.07 0 01-2.06 2.06zm14.6 12.87h-3.56v-5.55c0-1.32-.02-3.02-1.84-3.02-1.84 0-2.12 1.43-2.12 2.91v5.66H8.86V9.03h3.42v1.56h.05c.48-.91 1.65-1.87 3.39-1.87 3.63 0 4.3 2.39 4.3 5.5v6.23z" />
      </svg>
    </button></a>
                    </li>
                    <li>
  <a
    href="https://www.facebook.com/Flavie.A.pro"
    target="_blank"
    className="text-black transition-colors duration-300 "
    rel="noreferrer"
              >
                                <button  className="rounded-full hover:scale-105 hover:shadow-survol-circle">

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6"
    >
      <path d="M22.675 0H1.325C.593 0 0 .593 0 1.326v21.348C0 23.407.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.41c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24h-1.918c-1.504 0-1.796.714-1.796 1.762v2.311h3.588l-.467 3.622h-3.121V24h6.116C23.407 24 24 23.407 24 22.674V1.326C24 .593 23.407 0 22.675 0z" />
    </svg></button>
  </a>
</li>

      <li>
        <a
          href="https://github.com/Flavie-A"
          target="_blank"
          className="text-black transition-colors duration-300 "
          rel="noreferrer"
              >
           <button  className="rounded-full hover:scale-105 hover:shadow-survol-circle">

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-6"
          >
            <path d="M12 0C5.37 0 0 5.37 0 12c0 5.3 3.438 9.8 8.205 11.385.6.113.82-.26.82-.577 0-.285-.01-1.04-.015-2.04-3.338.726-4.042-1.61-4.042-1.61-.546-1.387-1.333-1.756-1.333-1.756-1.09-.744.083-.729.083-.729 1.205.085 1.84 1.238 1.84 1.238 1.07 1.835 2.807 1.305 3.492.998.108-.774.42-1.305.762-1.605-2.665-.305-5.467-1.333-5.467-5.93 0-1.31.468-2.38 1.235-3.22-.123-.305-.535-1.527.117-3.18 0 0 1.008-.323 3.3 1.23a11.52 11.52 0 0 1 3.003-.403c1.02.005 2.045.137 3.003.403 2.292-1.553 3.3-1.23 3.3-1.23.653 1.653.24 2.875.117 3.18.77.84 1.235 1.91 1.235 3.22 0 4.61-2.807 5.625-5.48 5.92.43.37.815 1.096.815 2.21 0 1.597-.015 2.885-.015 3.277 0 .32.217.694.825.576C20.565 21.795 24 17.3 24 12c0-6.63-5.37-12-12-12z" />
          </svg></button>
        </a>
                    </li>
                    <li>
  <a
    href="https://www.malt.fr/profile/flavieandre"
    target="_blank"
    className="text-black transition-colors duration-300"
    rel="noreferrer"
              >
   <button  className="rounded-full h-6 w-6 hover:scale-105 hover:shadow-survol-circle">

 <svg xmlns="http://www.w3.org/2000/svg"
	 viewBox="0 0 43 43">
<g>
	<path d="M34.9,8.1C32,5.3,29,7.1,27.1,9L9.2,26.9c-1.9,1.9-3.9,4.8-0.9,7.8c3,3,5.9,1,7.8-0.9L34,15.9C35.9,14,37.7,11,34.9,8.1z"
		/>
	<path d="M17.7,7.4l3.8,3.8l3.9-3.9c0.3-0.3,0.5-0.5,0.8-0.7c-0.4-2-1.6-3.9-4.7-3.9c-3.1,0-4.3,1.9-4.7,3.9
		C17.2,6.8,17.5,7.1,17.7,7.4z"/>
	<path d="M25.4,35.6l-3.9-3.9l-3.8,3.8c-0.3,0.3-0.6,0.6-0.9,0.8c0.4,2.1,1.7,4,4.7,4c3,0,4.2-1.9,4.7-4
		C25.9,36.1,25.7,35.9,25.4,35.6z"/>
	<path d="M16.1,16.6H8.8c-2.7,0-6.1,0.8-6.1,4.9c0,3,1.9,4.2,4,4.7C6.9,25.8,16.1,16.6,16.1,16.6z"/>
	<path d="M36.4,16.8c-0.2,0.3-9.4,9.5-9.4,9.5h7.2c2.7,0,6.1-0.6,6.1-4.9C40.3,18.4,38.5,17.2,36.4,16.8z"/>
	<path d="M18.5,14.2l1.3-1.3l-3.8-3.8c-1.9-1.9-4.8-3.9-7.8-0.9c-2.2,2.2-1.7,4.3-0.5,6.1C8.1,14.2,18.5,14.2,18.5,14.2z"/>
	<path d="M24.6,28.7L23.2,30l3.9,3.9c1.9,1.9,4.9,3.7,7.8,0.9c2.1-2.1,1.6-4.3,0.5-6.1C35,28.7,24.6,28.7,24.6,28.7z"/>
</g>
</svg>

</button>
  </a>
</li>

          </ul>
              
          
<button
              type="button"
              onClick={toggleModal}
              aria-label="Ouvrir le formulaire de contact"
              className="font-neuton py-2 px-6 tracking-wide rounded-full bg-black text-white shadow flex items-center justify-center transition duration-500 hover:shadow-survol-btn hover:scale-105"
            >
              <i className="fas fa-caret-right mr-1"></i>Me contacter
            </button>

           <Modal
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        modalContent={<ContactForm />}
        modalColor="bg-white"
      />
  </div>
</nav>

      
      
  );
};

export default Navbar;
