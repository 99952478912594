import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { useMediaQuery } from "./hook/useMediaQuery";
import "./App.css";
import Navbar from "./Navbar";
import Footer from "./footer/Footer";
import Diaporama from "./diaporama/Diaporama";
import DiaporamaMobile from "./diaporama/DiaporamaMobile";
import Modal from "./assets/Modal";

function App() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isModalVisible, setModalVisible] = useState(false);
  const [ModalContent, setModalContent] = useState(<></>);
  const [ModalColor, setModalColor] = useState("");

 const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  return (
    <>
     <Helmet>
        <title>Flavie André - Webdesign, Développement Front-End & Communication Digitale</title>
        <meta name="description" content="Flavie André, webdesigner, développeur front-end, services d'accompagnement en communication digitale." />
        <meta name="keywords" content="webdesign, UI/UX design, développement front-end, communication digitale, React, Savoie, Haute-Savoie, Flavie André" />
        
        {/* Open Graph meta tags */}
        <meta property="og:title" content="Flavie André - Webdesign, Développement Front-End & Communication Digitale" />
        <meta property="og:description" content="Flavie André - Création de sites web, développement front-end et services d'accompagnement en communication digitale." />
        <meta property="og:image" content="https://www.flavie-a.fr/www/img/logo192.png" />
        <meta property="og:url" content="https://www.flavie-a.fr" />
        <meta property="og:type" content="website" />
        
        {/* Meta robots */}
        <meta name="robots" content="index, follow" />
        
        {/* Canonical URL */}
        <link rel="canonical" href="https://www.flavie-a.fr" />
      </Helmet>
    <div className="font-neuton bg-[#FAFAFA] w-full overflow-x-hidden h-screen flex flex-col p-4 pt-0 md:px-10">
      <Navbar />
      { isMobile ? 
        <DiaporamaMobile 
          setModalContent={ setModalContent } 
          setModalColor={ setModalColor }
          toggleModal={ toggleModal }
        /> 
        : 
        <Diaporama 
          setModalContent={ setModalContent } 
          setModalColor={ setModalColor }
          toggleModal={ toggleModal }
          
        />
      }
      <Footer />

      <Modal
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        modalContent={ModalContent}
        modalColor={ModalColor}
      />
      </div>
      </>
  );
}

export default App;
