function Mentions() {
    return (
        <div className="bg-white p-4 rounded-lg shadow-md">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Mentions Légales</h1>

            <section className="mb-6">
                <h2 className="text-xl font-semibold text-gray-700 mb-2">Éditeur du site</h2>
                <p className="text-gray-600">Flavie André</p>
                <p className="text-gray-600">Siret : 523 278 521 00035</p>
                <p className="text-gray-600">Urssaf : 827 000002183 153226</p>
                <p className="text-gray-600">Site web : <a href="https://www.flavie-a.fr" className="text-blue-500 hover:underline">www.flavie-a.fr</a></p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold text-gray-700 mb-2">Hébergement</h2>
                <p className="text-gray-600">OVH - 2 rue Kellermann - 59100 Roubaix</p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold text-gray-700 mb-2">Propriété intellectuelle</h2>
                <p className="text-gray-600">Tous les contenus présents sur ce site (textes, logos, etc.) sont la propriété exclusive de Flavie André, sauf mention contraire. Toute reproduction, même partielle, est interdite sans autorisation préalable.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold text-gray-700 mb-2">Responsabilité</h2>
                <p className="text-gray-600">L'éditeur du site ne peut être tenu responsable des erreurs ou omissions dans les contenus diffusés ni des dommages liés à l'utilisation du site.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold text-gray-700 mb-2">Illustrations</h2>
                <p className="text-gray-600">Sauf mentions différentes, les illustrations présentes sur ce site sont issues de <a href="https://picsum.photos" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">picsum.photos</a>.</p>
            </section>
        </div>
    );
}

export default Mentions;
