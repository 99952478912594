import React from "react";

function Confidentialite() {
    return (
        <div className="bg-white p-4 rounded-lg shadow-md">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Politique de Confidentialité</h1>
            <p className="text-gray-600 italic">Date de mise à jour : Mars 2025</p>

            <section className="mb-6">
                <h2 className="text-xl font-semibold text-gray-700 mb-2">Introduction</h2>
                <p className="text-gray-600">
                    Flavie André s'engage à protéger la vie privée et à garantir la sécurité des données personnelles des visiteurs de son site. Cette politique de confidentialité explique comment sont collectées, utilisées et protégées les informations personnelles.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold text-gray-700 mb-2">Collecte des Données</h2>
                <p className="text-gray-600">
                    Ce site ne collecte pas de cookies. Cependant, nous utilisons Google Search Console pour analyser le trafic de notre site. Google Search Console peut collecter des informations anonymes sur votre utilisation du site, telles que les pages visitées, le temps passé sur chaque page, et d'autres statistiques d'utilisation. Ces données sont utilisées pour améliorer le site et l'expérience utilisateur.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold text-gray-700 mb-2">Utilisation des Données</h2>
                <p className="text-gray-600">
                    Les informations collectées par Google Search Console sont utilisées uniquement pour analyser le trafic du site et améliorer son contenu et sa structure. Ces données ne sont pas partagées avec des tiers, sauf si la loi l'exige.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold text-gray-700 mb-2">Vos Droits</h2>
                <p className="text-gray-600">
                    Vous avez le droit de demander l'accès à vos données personnelles, de les corriger ou de les supprimer. Pour exercer ces droits, veuillez contacter Flavie André via le formulaire de contact du site.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold text-gray-700 mb-2">Modifications de la Politique de Confidentialité</h2>
                <p className="text-gray-600">
                    Flavie André se réserve le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page qui précise la date de dernière mise à jour.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold text-gray-700 mb-2">Contact</h2>
                <p className="text-gray-600">
                    Pour toute questions concernant cette politique de confidentialité, veuillez contacter Flavie André via le formulaire de contact du site.
                </p>
            </section>
        </div>
    );
}

export default Confidentialite;
