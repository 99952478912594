import React from "react";
import { FaSearchPlus } from "react-icons/fa";

function SlideWebdesignModal() {
  const projects = [
    { 
      image: "https://picsum.photos/id/1015/600/400", 
      title: "Projet Portfolio", 
      description: "Design moderne et responsive pour un portfolio créatif."
    },
    { 
      image: "https://picsum.photos/id/1020/600/400", 
      title: "Landing Page", 
      description: "Page d'accueil immersive pour une startup innovante."
    },
    { 
      image: "https://picsum.photos/id/1035/600/400", 
      title: "E-commerce", 
      description: "Interface intuitive et ergonomique pour une boutique en ligne."
    },
    { 
      image: "https://picsum.photos/id/1040/600/400", 
      title: "Blog Minimaliste", 
      description: "Un blog épuré et élégant pour un créateur de contenu."
    }
  ];

  return (
    <div className="p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {projects.map((project, index) => (
          <div key={index} className="bg-white shadow-md rounded-lg overflow-hidden relative group">
            <div className="relative overflow-hidden">
              <img 
                src={project.image} 
                alt={project.title} 
                className="w-full h-48 object-cover transform transition duration-300 group-hover:scale-110" 
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                <FaSearchPlus className="text-white text-3xl" />
              </div>
            </div>

            {/* Description */}
            <div className="p-4 text-center">
              <p className="text-gray-600 text-sm">{project.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SlideWebdesignModal;
