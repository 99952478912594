import React from "react";

function Modal({ isModalVisible, toggleModal, modalContent, modalColor }) {
  
    if (!isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${
        isModalVisible ? 'visible opacity-100' : 'invisible opacity-0'
      } transition-opacity duration-500`}
      role="dialog"
      aria-modal="true"
      onClick={toggleModal}
    >
      <div
        className={`fixed bottom-0 ${modalColor} h-[95vh] rounded-lg shadow-lg w-11/12 md:w-8/12 p-6 transform transition-transform duration-500 ${
          isModalVisible ? 'translate-y-0' : 'translate-y-full'
        }`}
        onClick={(e) => e.stopPropagation()}  // Empêche la propagation du clic
      >
        <button
          onClick={toggleModal}
          aria-label="Fermer le modal"
          className="absolute bg-white top-3 right-3 border border-black rounded-full w-10 h-10 flex items-center justify-center hover:border-2 transition"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M6.225 6.225a.75.75 0 011.06 0L12 10.94l4.715-4.715a.75.75 0 111.06 1.06L13.06 12l4.715 4.715a.75.75 0 11-1.06 1.06L12 13.06l-4.715 4.715a.75.75 0 11-1.06-1.06L10.94 12 6.225 7.285a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        
        {/* Contenu défilable avec barre de défilement masquée */}
        <div className="overflow-y-auto max-h-[85vh] scrollbar-hidden">
          {modalContent}
        </div> 
      </div>
    </div>
  );
}

export default Modal;
