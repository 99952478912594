import React, { useState } from "react";
import SlideFrontEnd from "./slides/SlideFrontEnd";
import SlideWebdesign from "./slides/SlideWebdesign";
import SlideAccueil from "./slides/SlideAccueil";
import SlideCommDigitale from "./slides/SlideCommDigitale";


function Diaporama({ setModalContent, setModalColor, toggleModal }) {
  const [currentIndex, setCurrentIndex] = useState(0); // Suivi de l'index actuel

  const nextItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  // const goToSlide = (index) => {
  //   setCurrentIndex(index); // Définir l'index de la slide spécifique
  // };

    const items = [
    <SlideAccueil goToSlide={setCurrentIndex} setModalContent={setModalContent} setModalColor={setModalColor} toggleModal={toggleModal} />,
    <SlideFrontEnd goToSlide={setCurrentIndex} setModalContent={setModalContent} setModalColor={setModalColor} toggleModal={toggleModal} />,
    <SlideWebdesign goToSlide={setCurrentIndex} setModalContent={setModalContent} setModalColor={setModalColor} toggleModal={toggleModal} />,
    <SlideCommDigitale goToSlide={setCurrentIndex} setModalContent={setModalContent} setModalColor={setModalColor} toggleModal={toggleModal} />,
  ];
  
  return (
    <div className="flex justify-center items-center  relative">
      <button
        onClick={ prevItem }
        disabled={ currentIndex === 0 }
        className={ `p-2 rounded-full bg-white shadow-md hover:shadow-lg hover:bg-black hover:shadow-survol-circle hover:text-white transition ${currentIndex === 0 ? "hidden" : "opacity-100"}` }
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>

      <div className="flex justify-center items-center w-full mx-4 ">
        <div className="w-full flex justify-center items-center ">
        <section className="w-full h-70 flex items-start justify-center animate-slideIn">
            { items[currentIndex] }
            </section>
        </div>
      </div>

      <button
        onClick={nextItem}
        disabled={ currentIndex === items.length - 1 }
        className={ `p-2 rounded-full bg-white shadow-md hover:shadow-lg hover:bg-black hover:shadow-survol-circle hover:text-white transition ${currentIndex === items.length - 1 ? "hidden" : "opacity-100"}` }      >
        {/* Flèche droite */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  );
}

export default Diaporama;
