import React, { useState } from "react";
import emailjs from "@emailjs/browser";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSending, setIsSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.message) {
      alert("Veuillez remplir tous les champs.");
      return;
    }

    setIsSending(true);

    try {
      const response = await emailjs.send(
        "service_wrfkwjo", // Remplacez par votre Service ID EmailJS
        "template_g9hcqtk", // Remplacez par votre Template ID EmailJS
        {
          from_name: formData.name,
          from_email: formData.email,
          message: formData.message,
        },
        "TFAs0ctbQUiMpIPD0" // Remplacez par votre User ID EmailJS
      );

      if (response.status === 200) {
        setSuccessMessage("Votre message a été envoyé avec succès !");
        setFormData({ name: "", email: "", message: "" }); // Réinitialiser le formulaire
      } else {
        alert("Une erreur est survenue. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du message :", error);
      alert("Une erreur est survenue. Veuillez réessayer.");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="z-50 max-w-lg mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4 text-center">N'hésitez à prendre à contact, je serai ravie d'échanger au sujet de nouvelles missions !</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Nom */}
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Nom
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Votre nom"
          />
        </div>
        {/* Email */}
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Votre adresse email"
          />
        </div>
        {/* Message */}
        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows={4}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Votre message"
          />
        </div>
        {/* Bouton d'envoi */}
        <button
          type="submit"
          className={`font-neuton mx-auto py-2 px-6 text-lg tracking-wide rounded-full bg-black text-white shadow flex items-center justify-center"
 transition ${
            isSending
              ? "bg-gray cursor-not-allowed"
              : "transition duration-500 hover:shadow-survol-btn"
          }`}
          disabled={isSending}
        >
          {isSending ? "Envoi en cours..." : "Envoyer"}
        </button>
      </form>
      {/* Message de succès */}
      {successMessage && (
        <p className="mt-4 text-green-500 text-center">{successMessage}</p>
      )}
    </div>
  );
}

export default ContactForm;
