import React from "react";

function SlideFrontEndModal() {


  return (
             <div>
            <h2 className="text-xl font-bold mb-4">Prochainement !</h2>
            <p className="text-lg">
            </p>
          </div>
  );
}

export default SlideFrontEndModal;
